import LandingSection from '~/components/sections/LandingSection/LandingSection.vue';
import OurServicesSection from '~/components/sections/OurServicesSection/OurServicesSection.vue';
import ContactSection from '~/components/sections/ContactSection/ContactSection.vue';
import ProductListSection from '~/components/sections/ProductListSection/ProductListSection.vue';
import CopySection from '~/components/sections/CopySection/CopySection.vue';
import PageAsyncDataAbstract from '../PageAsyncDataAbstract';
import { ScrollTrackerComponentManager } from 'scroll-tracker-component-manager';
import { mapState, mapMutations } from 'vuex';
import { ControlNamespace, ControlMutationTypes } from '../../store/module/control';

export default {
  extends: PageAsyncDataAbstract,
  
  data() {
    return {
      totalBlocks: 4
    }
  },

  components: {
    LandingSection,
    ProductListSection,
    OurServicesSection,
    ContactSection,
    CopySection,
  },

  computed: {
    ...mapState(ControlNamespace, ['goSection']),
  },

  beforeCreate() {
    if (process.browser) {
      // Here we keep track of the block components on the page
      this.blockComponents = [];
      // Create the scroll tracker manager
      this.scrollTrackerComponentManager = new ScrollTrackerComponentManager({
        element: '$el',
        methods: {
          enterView: 'enterViewport',
          leaveView: 'leaveViewport',
          beyondView: 'beyondViewport',
        },
        vars: {
          enterViewThreshold: 'treshhold',
          componentId: 'id',
        },
        config: {
          setDebugLabel:false,
          // debugBorderColor: config.debug.scrollTrackerLabel.style.color,
          resizeDebounce: 100,
        },
      });
    }
   
  },

  mounted() {
    const {hash} = window.location;
    if(hash) {
      setTimeout(() => {
        document.querySelector(hash).scrollIntoView({
          behavior: 'smooth'
        })
      }, 500)
    }
  },

  methods: {

    ...mapMutations({
      setShowMenu: ControlMutationTypes.SHOW_MENU,
    }),

    handleOnReady(component) {
      // Register the new block
      this.blockComponents.push(component);
      clearTimeout(this._registerBlockTimeout);
        this._registerBlockTimeout = setTimeout(() => {
          // When all components are ready we start adding the blocks to the scroll tracker
            this.scrollTrackerComponentManager.addComponentsToScrollTrackers(this.blockComponents);
        }, 1000)
      }
  },

  watch: {
    goSection(section) {
      const comp = this.blockComponents.find(component => component.id === section);
      console.log(comp.$el.getBoundingClientRect().top)

      if(comp) {
        this.setShowMenu(false);
        const {top} = comp.$el.getBoundingClientRect();
        window.scrollTo({
          top: top - document.body.getBoundingClientRect().top,
          left: 0,
          behavior: 'smooth'
        });
      }
    }
  },

 
  beforeDestroy() {
  }

}